import React from 'react';
import { IconRefreshExclamationPoint } from '@hawkins/assets';
import { Colors } from '@hawkins/variables';

export const ThumbnailError = ({
  height = 40,
  children,
}: {
  height?: number;
  children?: React.ReactNode;
}): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        border: `1px solid ${Colors.GrayT40}`,
      }}
    >
      {children ? children : <IconRefreshExclamationPoint height={height} />}
    </div>
  );
};
