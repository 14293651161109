import { useEffect } from 'react';

interface OpenGraphImage {
  url: string;
  width?: number;
  height?: number;
  type?: string;
  alt?: string;
}

interface OpenGraphInput {
  title: string;
  description: string;
  images?: OpenGraphImage[];
  url?: string;
  type?: string;
}

function createOrUpdateMetaTag(property: string, content: string) {
  let metaTag = document.querySelector(`meta[property="${property}"]`);
  if (!metaTag) {
    metaTag = document.createElement('meta');
    metaTag.setAttribute('property', property);
    document.head.appendChild(metaTag);
  }
  metaTag.setAttribute('content', content);
}

function cleanup() {
  document.querySelectorAll('meta[property^="og:"]').forEach((metaTag) => {
    metaTag.remove();
  });
}

export function useOpenGraphProtocol(input?: OpenGraphInput) {
  useEffect(() => {
    if (!input) {
      cleanup();
      return;
    }
    createOrUpdateMetaTag('og:title', input.title);
    createOrUpdateMetaTag('og:description', input.description);
    if (input.images && input.images.length > 0) {
      input.images.forEach((image) => {
        createOrUpdateMetaTag(`og:image`, image.url);
        if (image.width) {
          createOrUpdateMetaTag(`og:image:width`, image.width.toString());
        }
        if (image.height) {
          createOrUpdateMetaTag(`og:image:height`, image.height.toString());
        }
        if (image.type) {
          createOrUpdateMetaTag(`og:image:type`, image.type);
        }
        if (image.alt) {
          createOrUpdateMetaTag(`og:image:alt`, image.alt);
        }
      });
    }
    if (input.url) {
      createOrUpdateMetaTag('og:url', input.url);
    }
    createOrUpdateMetaTag('og:type', input.type || 'website');

    return () => {
      document.querySelectorAll('meta[property^="og:"]').forEach((metaTag) => {
        metaTag.remove();
      });
    };
  }, [input]);
}
