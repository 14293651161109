import { ErrorState } from '@hawkins/components';
import { Dimensions } from '@hawkins/variables';
import React from 'react';

export const ShowNoAssetsBatch = (): JSX.Element => {
  return (
    <ErrorState
      layout={{
        padding: Dimensions.Space24,
      }}
      subtitle={`Please reach out to the friendly Netflix colleague who shared it for more information. If you are the owner, you can easily select assets to add to this batch.`}
      title='Looks like there are no assets here (yet).'
      variant='not-found'
    />
  );
};
